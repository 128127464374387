<template>
    <div style="height:100%;width:100">
        <el-table :data="datas" :row-key='rowkey' :tree-props="treeprops" @row-click="hanldeRowClick"  @row-dblclick="hanldeRowDBClick" @row-contextmenu="hanldeRowContextmenu" :height="tableHeight" stripe style="width: 100%;" :size="tablesize">
            <slot name="default" />
        </el-table>
        <div v-if="total!==null" class="page-container-content-footer">
            <el-pagination class="page-container-content-footer-bar" :current-page="page" :page-size="size" :total="total" @size-change="handleSizeChange" @current-change="handlePageChange"  :page-sizes="[5,10,15,20,30,50,100,200,300,400]" layout="total, sizes, prev, pager, next, jumper"/>
        </div>
    </div>
</template>
<script>
export default {
    name: "pageTable",
    props: {
        page : {type: Number,default: 1},
        size : {type: Number,default: 20},
        total: {type: Number,default: null},
        datas: {type: Array ,default: ()=>{return []}},
        rowkey    : {type: String ,default:''},
        treeprops : {type: Object ,default: ()=>{return {}}},
        tablesize : {type: String ,default:''},
    },
    data(){
        return {
            tableHeight:500
        }
    },
    updated(){
        this.$nextTick().then(()=>{
            this.tableHeight = this.total===null?(this.$el.offsetHeight):(this.$el.offsetHeight - 44);
        });
    },
    methods: {
        hanldeRowClick(row, column, event){
            this.$emit("row-click", {row, column, event});
        },
        hanldeRowDBClick(row, column, event){
            this.$emit("row-dbclick", {row, column, event});
        },
        hanldeRowContextmenu(row, column, event){
            this.$emit("row-context", {row, column, event});
        },
        handleSizeChange(val) {
            this.$emit("change", this.page,val);
        },
        handlePageChange(val) {
            this.$emit("change", val,this.size);
        }
    },
}
</script>
<style lang="scss" scoped>
.page-container-content-footer{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 44px;
    background-color: rgb(255, 255, 255);
    .page-container-content-footer-bar{
        float: right;
        font-size: 14px !important;
        margin: 5px 100px 0px 0px;
    }
}
</style>